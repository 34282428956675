export default class slick_slider {
    container = null;
    screen_size = null;
    breakdown = null;
    does_exist = $.isFunction($.fn.slick);

    // Default slick slider config
    config = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        adaptiveHeight: true,
        fade: true
    }

    constructor(container, screen_size = null, config) {
        // Set slider element
        this.container = container;

        // Set breakdown screen size for mobile
        this.screen_size = screen_size;

        // Check if screen size is mobile
        this.breakdown = ($(window).width() < this.screen_size ? true : false);

        // If config set override default config
        if(config) this.config = config;

        // Check if slick slider function exists then init slick slider
        if(this.does_exist) this.initSlickSlider();
    }

    initSlickSlider(){
        let $this = this;

        // Set slider element to be looped if multiple
        let $slider = $($this.container);

        // Loop through each slider element and initialize
        $slider.each(function(i,e){
            if(!$(e).hasClass('slick-initialized') && !$this.breakdown){
                $(e).slick($this.config);
            }
        });

    }
}