import { scrollToY, setCookie, getCookie, COOKIE_DOMAIN } from '../helpers.js';

(function ($) {

    class Favourites {

        oldCookieName = "favourites";
        cookieName = "favourites-v3";

        constructor() {
            this.favBadge = $('.fav-toggle .badge');
            this.upgradeCookie();
            this.toggleFavourites();
            this.highlightFavourites();
            $(document).on('highlightFavourites', () => {
                this.highlightFavourites();
            });
        }

        /**
         * Converts a v2 cookie to v3 (if v3 not yet set)
         */
        upgradeCookie() {
            let oldCookie = getCookie(this.oldCookieName);
            let newCookie = getCookie(this.cookieName);
            if (typeof newCookie === "undefined" && typeof oldCookie === "string") {
                // Cookie should be upgraded
                setCookie(this.cookieName, JSON.stringify({ domain: COOKIE_DOMAIN, favourites: oldCookie }));
                // Delete the old cookie
                setCookie(this.oldCookieName, "", COOKIE_DOMAIN, 0);
            }
        }

        headerCount() {
            let favourites = this.cookieToArray();
            if (favourites.length) {
                $('[data-favourite-view]').addClass('has-favourites');
                this.favBadge.html(favourites.length).show().attr('data-count', favourites.length)

            } else {
                $('[data-favourite-view]').removeClass('has-favourites');
                this.favBadge.hide().attr('data-count', 0)
            }
        }

        toggleFavourites() {

            $('[data-favourite-view]').on('click', (e) => {
                $.fancybox.open({
                    type: 'ajax',
                    src: '/study/favourites/',
                    autoFocus: false,
                    afterShow: () => {
                        this.highlightFavourites();
                    }
                });
            });

            $(document).on('click', 'button.send-favs-email', (e) => {
                const favsName = document.getElementById("favs_name");
                const favsEmail = document.getElementById("favs_email");

                const isValidForm = favsName.checkValidity() && favsEmail.checkValidity();

                if (isValidForm) {
                    this.emailFavourites();
                    return false;
                }

            });

            $(document).on('click', '[data-add-favourite]', (e) => {
                const id = $(e.target).attr('data-offering-id');

                if (id) {

                    let favourites = this.cookieToArray() ?? [];
                    let newFavourites = "";
                    const idLink = $(`[data-offering-id="${id}"]`);

                    if (favourites) {
                        // check if id is already a favourite
                        const isFav = favourites.find((fav) => {
                            return fav === id;
                        });

                        if (isFav) {
                            favourites = favourites.filter((isFav) => {
                                return isFav !== id;
                            });

                            idLink.removeClass('is-favourite');
                        } else {
                            favourites.push(id);
                            idLink.addClass('is-favourite');

                        }
                        newFavourites = favourites.join('|');
                    } else {
                        idLink.addClass('is-favourite');
                        newFavourites = id;
                    }
                    setCookie(this.cookieName, JSON.stringify({ domain: COOKIE_DOMAIN, favourites: newFavourites }));
                    this.headerCount();
                }                
                return false;
            });

        }

        highlightFavourites() {
            let cookie = JSON.parse(getCookie(this.cookieName) ?? "{}");
            let favourites = cookie.favourites;
            if (favourites) {
                favourites = favourites.split('|');
                favourites.map((item) => {
                    $(`[data-offering-id="${item}"]`).addClass('is-favourite');
                });
                this.headerCount();
            }
        }

        cookieToArray() {
            let cookie = JSON.parse(getCookie(this.cookieName) ?? "{}");
            return cookie?.favourites ? cookie.favourites.split('|') : false;
        }

        emailFavourites() {

            const email = $('.favs__toolbar');
            const userEmail = $('.favs__toolbar-form input[type=email]').val();
            const userName = $('.favs__toolbar-form input[type=name]').val();

            email.attr('data-email-status', 'sending');

            const cookie = JSON.parse(getCookie(this.cookieName) ?? "{}");

            $.ajax({
                url: siteData.ajaxURI,
                method: 'GET',
                data: {
                    action: 'send_fav',
                    name: userName,
                    email: userEmail,
                    favs: cookie?.favourites ?? "",
                },
                success: function (response) {
                    $('.favs__response').addClass('active');
                    $('.favs__toolbar').hide();

                    if (response == 'success') {
                        $('.favs__response-success').fadeIn('slow');
                    } else {
                        $('.favs__response-fail').fadeIn('slow');
                    }
                },
                error: function (request, status, error) {
                    console.log(request.responseText);
                }
            });
        }
    }

    new Favourites();

})(jQuery);
