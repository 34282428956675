// import Fancybox from '@fancyapps/fancybox';
import { setCookie, getCookie, scrollToY } from "../helpers";

jQuery(function($) {

    class Personalisation {

        constructor() {
            const segment = getCookie('user_region');

            let params = (new URL(document.location)).searchParams;
            let segmentFromURL = params.get('segment');

            // Set personalisation button and modal elements
            let $personalisation_modal          = $('#personalisation-modal');
            let $personalisation_modal_trigger  = $('[data-personalisation-modal]');

            // is there a valid ?segment query string? use that
            if (segmentFromURL && (segmentFromURL === 'int' || segmentFromURL === 'dom')) {
                setCookie('user_region', segmentFromURL, '/', 86400);
            } else if (!segment) {
                if ($personalisation_modal.length && !getCookie('has_seen_personalisation') && !$personalisation_modal.is('dialog')) {
                    $.fancybox.open({
                        src: '#personalisation-modal',
                        autoFocus: false,
                        clickSlide: false,
                        smallBtn: false,
                        toolbar: false,
                        keyboard: false,
                        afterClose: (instance, slide) => {
                            // if user just closes the modal, set the segment cookie based on the AU_VISITOR cookie
                            if(getCookie('region') === undefined) {
                                setCookie('user_region', this.isIntString(), '/', 86400);
                            }
                            setCookie('has_seen_personalisation', true);
                        }
                    });
                } else if($personalisation_modal.length && !getCookie('has_seen_personalisation') && $personalisation_modal.is('dialog')){
                    $personalisation_modal[0].showModal();
                } else {
                    setCookie('user_region', 'dom', '/', 86400);
                }
            }

            // Check if personalisation modal is a dialog element
            if(!$personalisation_modal.is('dialog')){
                $personalisation_modal_trigger.fancybox({
                    src: '#personalisation-modal',
                    autoFocus: false,
                    smallBtn: false,
                    toolbar: false,
                    clickSlide: false,
                    keyboard: false,
                });
            } else {
                // if personalisation modal is dialog set in built event handlers
                $personalisation_modal_trigger.on('click', function(){
                    $personalisation_modal[0].showModal();
                });

                $personalisation_modal.find('.modal-close').on('click', function(){
                    $personalisation_modal[0].close();
                });
            }

        }

        isInt() {
            return getCookie('AU_VISITOR') === "0";
        }

        isIntString() {
            return this.isInt() ? 'int' : 'dom';
        }
    }

    new Personalisation();

});
