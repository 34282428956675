$(() => {
    // Set modal links
    let modal_link = $('.modal-link');

    // Loop through each modal link and get modal ID
    modal_link.each(function(i,e){
        // Get modal id and set modal element
       let modal_id = $(e).data('src');
       let $modal = $(modal_id);

       // Check if modal is a dialog element
       if($modal.is('dialog')){

           // Open modal on link click
          $(e).on('click', function(){
              // Open modal
              $modal[0].showModal();
          });

          // CLose modal on button click
          $modal.find('.modal-close').on('click', function(){
             $modal[0].close();
          });
       }
    });

    // Modal trigger via href
    $('a[href*=":modal="]').each(function() {
        var $this = $(this);
        // split the link ref and grab the part after :modal=
        var modal_link = $this.attr('href').split(':modal=')[1];

        // remove link href and add the req modal attributes
        $this.removeAttr('href', '').attr('data-src', '#' + modal_link);

        let $modal = $('#' + modal_link);

        if($modal.length){
            // Bind click event handler to open dialog modal
            $(this).on('click', function(){
                // open dialog modal
                $modal[0].showModal();
            });

            // Bind click event handler to close dialog modal
            $modal.find('.modal-close').on('click', function(){
                // close dialog modal
                $modal[0].close();
            });
        } else {
            console.log('This modal doesn\'t exist.');
        }
    });

    // open modal based on # parameter
    // works for both normal and form modals
    // e.g. http://example.com/#my-modal
    // e.g. http://example.com/#form-modal-3355
    // check for = char as it will throw a syntax error
    let modal_id = document.location.hash;

    let $modal = $(CSS.escape(modal_id));
    let modal_exists = $modal.length;
    let modal_is_dialog = $modal.is('dialog');
    let has_class_modal = $modal.hasClass('modal');

    if(modal_exists && !modal_is_dialog && has_class_modal) {
        $.fancybox.open({
            src: modal_id
        });
    } else if(modal_exists && modal_is_dialog && has_class_modal){
        $(modal_id)[0].showModal();

        $(modal_id).find('.modal-close').on('click', function(){
            $modal[0].close();
        });
    }
});