import ScrollMagic from 'scrollmagic';

export default class scroll_magic_animation {
    element = null;
    animation = null;

    constructor(element, animation){
        // Bind elements and animation
        this.element = element;
        this.animation = animation;

        // Init scroll magic animation
        this.init_animation();
    }

    init_animation(){
        let _t = this;

        // Set animation controller
        var controller = new ScrollMagic.Controller();

        // Set target to be animated
        var $target = $(_t.element);

        // Loop through each target and apply animation
        $target.each(function () {
            // Set animate class to hide elements to accommodate for animation
            $(this).addClass('animate');

            var scene = new ScrollMagic.Scene({
                triggerElement: this,
                triggerHook: 0.8
            })
                .setClassToggle(this, _t.animation)
                .reverse(false)
                .addTo(controller);

        });
    }
}