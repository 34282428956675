import scroll_magic_animation from '../src/scroll-magic';

$(() => {
    // Set block name, item to be animated and animation type
    // Refer to _animation.scss src/scss/base/_animations.scss for animation classes
    let animate_blocks = [
        {
            name: 'cta-squares',
            item: '.square',
            animation: 'animate--slide-bottom'
        },
        {
            name: 'cards',
            item: '.card',
            animation: 'animate--slide-bottom'
        },
        {
            name: 'facts',
            item: '.card',
            animation: 'animate--slide-bottom'
        },
        {
            name: 'cards-image',
            item: '.card',
            animation: 'animate--slide-bottom'
        },
        {
            name: 'checkered-items',
            item: '.checkered-item:nth-child(odd) img',
            animation: 'animate--slide-right'
        },
        {
            name: 'checkered-items',
            item: '.checkered-item:nth-child(odd) .checkered-item__content',
            animation: 'animate--slide-left'
        },
        {
            name: 'checkered-items',
            item: '.checkered-item:nth-child(even) img',
            animation: 'animate--slide-left'
        },
        {
            name: 'checkered-items',
            item: '.checkered-item:nth-child(even) .checkered-item__content',
            animation: 'animate--slide-right'
        },
        {
            name: 'hover-content',
            item: '.hover-content__item:first-child() .hover-content__item-content ',
            animation: 'animate--slide-left'
        },

        {
            name: 'action-panel',
            item: '.card',
            animation: 'animate--slide-bottom'
        },
    ]

    // Apply animations to only tablet and higher screen sizes
    if($(window).width() >= 768) {
        // Loop through each animated block in array
        $.each(animate_blocks, (i, block) => {
            // Check if block exists
            if($('[block-name="' + block.name + '"]').length) {
                let animated_blocks = new scroll_magic_animation($('[block-name="' + block.name + '"] ' + block.item), block.animation);
            }
        });
    }
});