import {debounce, throttle} from "throttle-debounce";

(function($) {

    function accordionAriaToggle($this) {
        // toggle aria controls
        var state = $this.parent('.accordion').hasClass('active');

        $this.attr('aria-expanded', state)
            .next('.accordion__content')
            .attr('aria-hidden', !state);
    }

    function accordionInit() {
        // check if an accordion has been requested via url
        let hash = window.location.hash ?? false;

        //Fix URL's with trailing / not finding accordion
        hash = hash.substring(1).endsWith("/") ? hash.slice(0, -1) : hash;

        let $target = '';

        if($('.accordion').children('button').is($('.accordion__heading[aria-controls="' + hash.substring(1) + '"]'))) {
            $target = $('.accordion__heading[aria-controls="' + hash.substring(1) + '"]').parent('.accordion');
        }

        if(hash && $target.length) {
            // Scroll to the accordion
            $([document.documentElement, document.body]).animate({
                // Offset for sticky header
                scrollTop: ($target.offset().top - 110)
            }, 1000);

            // Set accordion state
            $target.addClass('active');

            // Set accordion open
            $target.find('.accordion__content').slideToggle();

            // Set aria attributes to true
            accordionAriaToggle($target.children('.accordion__heading'));
        }

        // Set accordion button element
        let accordion_toggle = '.accordion__heading';

        // Bind event click on toggle button
        $(document).on('click', accordion_toggle, debounce(100, function() {
            // Set elements for aria toggle and accordion toggle
            let $target     = $(this);
            let $accordion  = $(this).parent('.accordion');
            let $content    = $accordion.find('.accordion__content');
            let mode        = ($accordion.hasClass('single')) ? true : false;

            // Temp fix for manual accordions block on new applying page, TODO: remove this code once block updated to new faq
            if($('.page-apply')) {
                mode = true;
            }

            // Set single accordion open method
            if(mode){
                let $accordions = $accordion.siblings('.active');

                $accordions.each(function(i,e){
                   let $accordion = $(e);
                   let $accordion_heading = $accordion.find('.accordion__heading');
                   let $accordion_content = $accordion.find('.accordion__content');

                    $accordion.removeClass('active');
                    $accordion_content.slideUp();

                    $accordion_heading.attr('aria-expanded', false);
                    $accordion_content.attr('aria-hidden', true);
                });
            }

            // Close open accordion ?
            $(this).attr('aria-expanded','false').parent().find('.accordion.active').removeClass('active');

            // Toggle accordion state
            $accordion.toggleClass('active');

            // Toggle accordion content
            if(!$accordion.parent('.usps-accordion').length) {
                ($content.is(':hidden')) ? $content.slideDown() : $content.slideUp();
            }

            // Toggle aria attributes
            accordionAriaToggle($target);
        }));
    }

    $(() => {
        if($('.accordion').length || $('.pathways').length) accordionInit();
    });
})(jQuery);
