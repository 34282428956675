import {throttle} from "throttle-debounce";
import {matchHeights} from "../helpers";
import {debounce} from 'throttle-debounce';
import slick_slider from '../src/slick-sliders';
import scroll_magic_animation from '../src/scroll-magic';

(function($) {


    function actionSliderInit() {


        $('.action-panel[data-slider-active="true"]').each(function() {
            let $actionSlider = $(this).find('.action-panel__slider .card-grid');

            $actionSlider.slick({
                slidesToShow: 1,
                arrows: false,
                dots: true,
                appendDots: $(this).find('.slider-controls'),
                fade: true,
                cssEase: 'linear',
                rows: 2,
                slidesPerRow: 1,
            });
        });
    }

    /*
    * Use the position of the content box to set the position of the slide controls
    * */
    function setGalleryControlPos($gallery, box) {

        let $controlWidth = box.find('.slide__content-wrap').width();
        let $controlPos = box.offset().left + $controlWidth + 'px';

        let styles = {
            '--control-pos': $controlPos
        };

        $gallery.find('.gallery__controls').css(styles);
    }


    function a_whatSettings($whatObject) {
        return {
            Arrows: false,
            dots: true,
            prevArrow: $whatObject.find(".slide__prev"),
            nextArrow: $whatObject.find(".slide__next"),
            appendArrows: $whatObject.find(".controls"),
            centerMode: true,
            infinite: true,
            centerPadding: '100px',
            mobileFirst: true,
            responsive: [
                {
                    breakpoint: 1280,
                    settings: "unslick",
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        centerPadding: '60px',
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        centerPadding: '60px',
                    }
                },
                {
                    breakpoint: 400,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        centerPadding: '0px',
                    }
                }
            ],
        };
    }

    function largeSliderInit() {

        $('.gallery[data-gallery-type="large"]').each(function() {
            let $this = $(this);
            let slickStage = $this.find('.gallery__stage');
            let containers = slickStage.find('.slide .gallery__container');

            $(slickStage).slick({
                arrows: true,
                dots: false,
                fade: true,
                prevArrow: $this.find('.slider-prev'),
                nextArrow: $this.find('.slider-next'),
                cssEase: 'linear',
            });

            matchHeights(containers); // we need this to correctly position the controls on mobile

            setGalleryControlPos($this, containers.first());

            $(window).on('resize', throttle(300, function() {
                matchHeights(containers); // we need this to correctly position the controls on mobile
                setGalleryControlPos($this, containers.first());
            }));


        });

    }

    function gallerySliderInit() {
        $('.gallery[data-gallery-type="strip"]').each(function() {
            let slickStage = $(this).find('.gallery__stage');

            $(slickStage).on('init', function() {
                $(slickStage).find('.slick-slide').each(function(slide) {
                    var img = $(this).find('img')[0];
                    img.onload = function() {
                        var w = img.naturalWidth;
                        var h = img.naturalHeight;
                        $(this).parents('.slick-slide').addClass(w > h ? 'landscape' : 'portrait');
                    }
                });
            });

            $(slickStage).slick({
                dots: false,
                arrows: true,
                prevArrow: $(this).find('.slider-prev'),
                nextArrow: $(this).find('.slider-next'),
                variableWidth: true,
                cssEase: 'linear',
                slidesToScroll: 1,
                speed: 300,
                infinite: true
            });


            $(slickStage).find('.slick-slide').on('click', function(slide) {
                let id = $(this).attr('data-slick-index');
                $(slickStage).slick('slickGoTo', id);
            });

            $(slickStage).find('.slick-slide').on('onAfterChange', function($slide) {
                $(slickSlide).slick('refresh');
            });
        });


    }

    function imageSliderInit() {
        $('.image-slider').each(function() {
            let slickId = '#' + $(this).find('.image-slider__stage').attr('id');

            $(slickId).slick({
                arrows: false,
                dots: true,
                appendDots: slickId + ' + .slider-controls',
                fade: true,
                cssEase: 'linear',
            });
        });
    }

    // set height for all items to tallest item
    function setItemHeight() {
        let tallest = 300;

        $('.stories__content-container').each(function() {
            let this_height = $(this).outerHeight();
            // is this height greater than current tallest height?
            tallest = this_height > tallest ? this_height : tallest;
        }).css('min-height', (tallest + 'px'));
    }

    function relatedPostsInit() {
        let related_posts_settings = {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            adaptiveHeight: false,
            cssEase: 'linear',
            touchThreshold: 20,
            rows: 0,
            arrows: false,
            mobileFirst: true,
            responsive: [
                {
                    breakpoint: 500,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 1024,
                    settings: 'unslick'
                }
            ]
        }

        let $relatedPosts = $('.related-posts .thumb-container');
        let isSlider = ($(window).outerWidth() < 1024);

        if(isSlider && !$relatedPosts.hasClass('slick-initialized')) {
            $relatedPosts.slick(related_posts_settings);
        } else if(!isSlider && $relatedPosts.hasClass('slick-initialized')) {
            $relatedPosts.slick('unslick');
        }

        let windowWidth = $(window).outerWidth();

        $(window).resize(debounce(500, function() {
            let windowWidthChanged = ($(window).outerWidth() != windowWidth);
            isSlider = ($(window).outerWidth() < 1024);

            if(windowWidthChanged && isSlider && !$relatedPosts.hasClass('slick-initialized')) {
                $relatedPosts.slick(related_posts_settings);
            } else if(windowWidthChanged && !isSlider && $relatedPosts.hasClass('slick-initialized')) {
                $relatedPosts.slick('unslick');
            }
        }));
    }

    // do this before slick is init for stories
    if($('.block.stories').length) {
        setItemHeight();
    }

    function stories_slider_init() {
        $('[block-name="stories"]').each(function(i, block) {
            // Bind stories block elements
            let $block = $(block);
            let preview_count = $block.find('.stories__items').length - 1;
            let $slide_prev = $block.find('.slick-slider__navigation--prev');
            let $slide_next = $block.find('.slick-slider__navigation--next');
            let $stories_preview = $block.find('.stories__preview');
            let $stories_images = $block.find('.stories__images');
            let $stories_content = $block.find('.stories__content');

            // Preview images slider config
            let preview_config = {
                dots: false,
                arrows: false,
                infinite: true,
                speed: 300,
                slidesToShow: preview_count,
                centerPadding: "10px",
                vertical: true
            }

            // Tall images slider config
            let images_config = {
                dots: false,
                arrows: false,
                infinite: true,
                speed: 300,
                slidesToShow: 1,
                slidesToScroll: 1,
                fade: true
            }

            // Story content config
            let content_config = {
                dots: false,
                arrows: false,
                infinite: true,
                speed: 300,
                slidesToShow: 1,
                slidesToScroll: 1,
                fade: true
            }

            // Init each slider
            let stories_slider_preview = new slick_slider($stories_preview, null, preview_config);
            let stories_slider_images = new slick_slider($stories_images, null, images_config);
            let stories_slider_content = new slick_slider($stories_content, null, content_config);

            // Bind previous control to all sliders
            $slide_prev.on('click', function() {
                $stories_preview.slick('slickPrev');
                $stories_images.slick('slickPrev');
                $stories_content.slick('slickPrev');
            });

            // Bind next control to all sliders
            $slide_next.on('click', function() {
                $stories_preview.slick('slickNext');
                $stories_images.slick('slickNext');
                $stories_content.slick('slickNext');
            });

            // Bind preview image navigation to elements
            $block.find('.slick-slide').on('click', function() {
                let slide = $(this).data('slick-index');

                $stories_preview.slick('slickGoTo', slide);
                $stories_images.slick('slickGoTo', slide);
                $stories_content.slick('slickGoTo', slide);
            });
        });
    }

    function media_slider_init() {
        let content_config = {
            dots: false,
            arrows: false,
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: true
        }

        let images_config = {
            dots: true,
            arrows: false,
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: true,
            appendDots: null
        }

        $('[block-name="media-slider"]').each(function(i, e) {
            // Set prev and next slick controls per block
            let $slide_prev = $(e).find('.slick-slider__navigation--prev');
            let $slide_next = $(e).find('.slick-slider__navigation--next');

            // Set content and image elements per block
            let $media_slider_content = $(e).find('.media-slider__items');
            let $media_slider_images = $(e).find('.media-slider__images');

            // Init Content Slider
            let media_slider_content = new slick_slider($media_slider_content, null, content_config);

            // Bind dots to slick dots component
            images_config.appendDots = $(e).find('.slick-slider__dots');

            // Init Image Slider
            let media_slider_images = new slick_slider($media_slider_images, null, images_config);

            // Bind previous control to all sliders
            $slide_prev.on('click', function() {
                $media_slider_content.slick('slickPrev');
                $media_slider_images.slick('slickPrev');
            });

            // Bind next control to all sliders
            $slide_next.on('click', function() {
                $media_slider_content.slick('slickNext');
                $media_slider_images.slick('slickNext');
            });

            // Bind media slider image dots to media slider content
            $media_slider_images.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
                $media_slider_content.slick('slickGoTo', nextSlide);
            });
        });
    }

    function card_carousel_slider_init() {
        let card_carousel_config = {
            dots: true,
            arrows: false,
            infinite: true,
            speed: 300,
            slidesToShow: 3,
            adaptiveHeight: true,
            appendDots: null,
            responsive: [
                {
                    breakpoint: 1280,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        }

        $('[block-name="card-carousel"]').each(function(i, e) {
            // Set prev and next slick controls per block
            let $slide_prev = $(e).find('.slick-slider__navigation--prev');
            let $slide_next = $(e).find('.slick-slider__navigation--next');

            // Set slider element per block
            let $card_carousel_slider = $(e).find('.card-carousel__slider');

            // Bind animation to card carousel
            let card_carousel_animation = new scroll_magic_animation($card_carousel_slider, 'animate--slide-right--double');

            // Bind dots to slick dots component
            let $card_carousel_dots = $(e).find('.slick-slider__dots');

            // Bind card carousel dots to sick element
            card_carousel_config.appendDots = $card_carousel_dots;

            // Init Cards Carousel Slider
            let card_carousel = new slick_slider($card_carousel_slider, null, card_carousel_config);

            // Bind previous control to all sliders
            $slide_prev.on('click', function() {
                $card_carousel_slider.slick('slickPrev');
                $card_carousel_slider.slick('slickPrev');
            });

            // Bind next control to all sliders
            $slide_next.on('click', function() {
                $card_carousel_slider.slick('slickNext');
                $card_carousel_slider.slick('slickNext');
            });
        });
    }

    function whats_on_slider_init() {

        let whats_on_config = {
            slider: {
                dots: true,
                arrows: false,
                infinite: true,
                speed: 300,
                slidesToShow: 1,
                appendDots: null,
                mobileFirst: true,
                responsive: null,
                variableWidth: true
            },
            grid: [
                {
                    breakpoint: 1279,
                    settings: "unslick"
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2
                    }
                }
            ],
            compact: [
                {
                    breakpoint: 1527,
                    settings: {
                        slidesToShow: 3,
                        variableWidth: false
                    }
                },
                {
                    breakpoint: 1279,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2
                    }
                }
            ]
        }

        $('[block-name="whats-on"]').each(function(i, e) {
            // Set content element per block
            let $whats_on_content = $(e).find('.content--desktop');
            let $whats_on_tab = $(e).find('.tab-group__link');
            let whats_on_variant = $(e).data('variant');

            $whats_on_content.each(function(int, ele) {
                // Set prev and next slick controls per tab content area
                let $slide_prev = $(ele).find('.slick-slider__navigation--prev');
                let $slide_next = $(ele).find('.slick-slider__navigation--next');

                // Set slider per tab content area
                let $whats_on_slider = $(ele).find('.whats-on__slider');

                // Bind dots to slick dots component per tab content area
                let $whats_on_dots = $(ele).find('.slick-slider__dots');

                // Bind Whats on dots to sick element per tab content area
                whats_on_config.slider.appendDots = $whats_on_dots;

                // Set slider responsive values based on variant
                whats_on_config.slider.responsive = (whats_on_variant === 'grid' || whats_on_variant === 'extended' ) ? whats_on_config.grid : whats_on_config.compact;

                // Init Whats on Slider per tab content area
                let whats_on = new slick_slider($whats_on_slider, null, whats_on_config.slider);

                // Bind previous control to all sliders per tab content area
                $slide_prev.on('click', function() {
                    $whats_on_slider.slick('slickPrev');
                    $whats_on_slider.slick('slickPrev');
                });

                // Bind next control per tab content area
                $slide_next.on('click', function() {
                    $whats_on_slider.slick('slickNext');
                    $whats_on_slider.slick('slickNext');
                });
            });

            // Loop through each tab element and bind event handler
            $whats_on_tab.each(function(int, ele) {
                // On click set position of slider to resolve tab display issue
                $(ele).on('click', function() {
                    $('.whats-on__slider').slick('setPosition')
                });
            });
        });
    }

    function responsiveSlider() {
        if(window.matchMedia('(min-width: 1279px)').matches) {
            // Initialize the slick slider if screen size is smaller or equal to 1279px
            if($('mimas-block:not([data-variant="compact"]) .whats-on__slider').hasClass('slick-initialized')) {
                $('.whats-on__slider').slick('unslick');
            }
        } else {
            // Destroy the slick slider if screen size is larger than 1279px
            if(!$('.whats-on__slider').hasClass('slick-initialized')) {
                whats_on_slider_init();
            }
        }
    }


    /* Masthead slider */
    function mastheadSliderInit() {

        let masthead_config = {
            dots: true,
            arrows: true,
            prevArrow: $('mimas-masthead .slick-slider__navigation--prev'),
            nextArrow: $('mimas-masthead .slick-slider__navigation--next'),
            appendDots: 'mimas-masthead .slick-slider__dots',
            adaptiveHeight: false,
            autoplay: true,
            autoplaySpeed: 5000,
            fade: true,
            cssEase: 'linear',
            pauseOnHover: true,
            touchThreshold: 20
        }

        let masthead_slider = new slick_slider($('mimas-masthead[data-mh-type="slider"]').find('.mh-stage'), null, masthead_config);
    }

    let aSliderBlocks = {
        'mimas-masthead[data-mh-type="slider"]': {call: 'mastheadSliderInit'},
        '[block-name="whats-on"]': {call: 'whats_on_slider_init'},
        '[block-name="card-carousel"]': {call: 'card_carousel_slider_init'},
        '[block-name="media-slider"]': {call: 'media_slider_init'},
        '[block-name="stories"]': {call: 'stories_slider_init'},
        '.image-slider__slide': {call: 'imageSliderInit'},
        '.gallery[data-gallery-type="large"]': {call: 'largeSliderInit'},
        '.gallery[data-gallery-type="strip"]': {call: 'gallerySliderInit'},
        '.action-panel[data-slider-active="true"]': {call: 'actionSliderInit'},
        '.related-posts .thumb-container .thumb': {call: 'relatedPostsInit'},
    };

    $(window).resize(debounce(500, responsiveSlider));

    $(document).ready(function() {

        // loop through and init registered sliders
        Object.entries(aSliderBlocks).forEach(entry => {
            const [key, value] = entry;

            if($(key).length) {
                let args = value.args ?? "";
                eval(value.call)(args);

            }

        });

        if($('[block-name="testimonial"]').length) {
            let testimonial_config = {
                dots: true,
                arrows: false,
                infinite: true,
                speed: 300,
                slidesToShow: 1,
                adaptiveHeight: true,
                fade: true,
                autoplay: true,
                autoplaySpeed: 5000,
                cssEase: 'linear'
            }

            let testimonial_slider = new slick_slider($('[block-name="testimonial"]').find('.testimonial__slider'), null, testimonial_config);
        }

        // match window to 768px width, returns true or false.
        const mq = window.matchMedia("(max-width: 768px)");
        mq.addListener(pauseSlider);

        // if window width less than 768 pause slick slider, else play slider.
        function pauseSlider(mq) {
            if(mq.matches) {
                $('.card-ms__stage').slick('slickPause');
            } else {
                $('.card-ms__stage').slick('slickPlay');
            }
        }
    });

})(jQuery);
