import {hasForms2} from "../helpers";

// Toggles modals referenced from an anchor tag with <a href="#forms-YOUR-FORM-ID">Modal link</a>
function init_forms_modal(forms_modal){
    forms_modal.each(function(i,form){
        // Set modal trigger and element
        let $form_trigger = $('a[href="#form-modal-'+$(this).data('formId')+'"], a[data-src="#form-modal-'+$(this).data('formId')+'"], a[href="#forms-'+$(this).data('formId')+'"]');

        // Check if modal is a dialog element
        if($(this).is('dialog')){
            // Open dialog modal
            $form_trigger.on('click', function(e){
                e.preventDefault();
                $(form)[0].showModal();
            });

            // Close dialog modal
            $(form).find('.modal-close, .modal-success__close').on('click', function(){
                $(form)[0].close();
            });
        } else {
            // Open legacy modal
            $form_trigger.on('click', function(e){
                e.preventDefault();
                $(form).fancybox();
            });
        }

        if($(this).data('formType') == 'marketo' && hasForms2()) {
            MktoForms2.loadForm("//app-sn03.marketo.com", "091-LWC-058", $(this).data('formId'));
        }
    });
}

$(() => {
    let $forms_modal = $('.forms-modal');

    // Check if forms modal element exists
   if($forms_modal.length){
       init_forms_modal($forms_modal);
   }

    $('.ci-blanket__toggle').click(function() {
        // if using masthead slider, go to the first slide
        // resize will cause a slick refresh - buggy otherwise
        $('.masthead-slider__stage').slick('resize');

        $('.ci-blanket').fadeToggle();
    });

    if($('.ci-blanket').length) {
        $('main.page-container').hide();
    }
});

