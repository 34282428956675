import {isMobile} from "../../helpers";

(function($) {
    // Add active class to ancestor if on current page
    const ancestors = $('.global-nav__item--top:has(a[href="'+window.location.href+'"])');
    if (ancestors.length == 1) {
        ancestors.addClass('current-menu-ancestor');
    } else {
        const ancestor = $('.global-nav__item--top:has(a[href="'+window.location.href+'"][data-depth="0"])')
        if (ancestors.length > 1 && ancestor.length == 1) {
            ancestors.removeClass('current-menu-ancestor');
            ancestor.addClass('current-menu-ancestor');
        }
    }

    $('.header-nav__trigger').on('click', () => {
        if(isMobile() == true) {
            $('body').toggleClass('global-nav-active');
            $('.header').toggleClass('active');
            $('.header-nav__trigger, .header-nav__trigger .hamburger').toggleClass('is-active');
            $('.global-nav').find('.active').removeClass('active');
        }
    });

    $('.global-nav__item .global-nav__link-trigger').on('click', function(e) {
        if(isMobile() == true) {
            const targetItem = $(this).closest('.global-nav__item');

            if(targetItem.hasClass('active')) {
                targetItem.removeClass('active');
                targetItem.find('.active').removeClass('active');

            } else {
                targetItem.toggleClass('active');
                targetItem.find('.global-nav__list').first().toggleClass('active');
                targetItem.siblings().removeClass('active');
                targetItem.siblings().find('.active').removeClass('active');
            }
        }
    })

    $(window).on('scroll', function() {
        if(!$('.header__navs--disabled').length) {
            if($('.menu-type-global').length && $('.menu-type-global .header-nav__utils').isOutOfViewport()) {
                $('.header').addClass('sticky');
            } else {
                $('.header').removeClass('sticky');
            }
        }
    });


    $.fn.isOutOfViewport = function() {
        if (this.length == 0) return false;
        var elementTop = $(this).offset().top;
        var elementBottom = elementTop + $(this).outerHeight();
        var viewportTop = $(window).scrollTop();

        return elementBottom < viewportTop;
    };

})(jQuery);


