import hoverintent from 'hoverintent';
import { isMobile } from "../../helpers";
import { throttle } from "throttle-debounce";

(function ($) {
    const ADMIN_BAR_SELECTOR = '#wpadminbar';
    const BASE_SELECTOR = 'main > aside';
    const NAV_SELECTOR = '.onpage-nav';
    const ITEM_SELECTOR = `${NAV_SELECTOR}__item`;
    const TITLE_SELECTOR = `${NAV_SELECTOR}__title`;
    const HEADER_SELECTOR = 'header.header';
    const ACTIVE_SELECTOR = 'on-hover';

    function pageNav() {
        const isAdmin = !!($(ADMIN_BAR_SELECTOR)?.length > 0);
        const onpageBase = $(BASE_SELECTOR);
        const onpageNav = $(NAV_SELECTOR);
        const topTitle = $(TITLE_SELECTOR);
        const headerElement = $(HEADER_SELECTOR);
        
        const onpageParents = onpageBase.find(`${ITEM_SELECTOR}--top.menu-item-has-children`);
        let topOffset, breadcrumbTopOffset;

        // Calculate and set the onpage nav top offset
        function setOnpageNavOffset() {
            // Calculate header and sub navigation height
            topOffset = ($('.subsite-nav').length) ? headerElement.outerHeight() + $('.subsite-nav').outerHeight() : headerElement.outerHeight();
            // Also ensure admin bar height is included
            onpageBase.css({ '--onpage-nav-top-offset': isAdmin ? 'calc(' + topOffset + 'px + var(--wp-admin--admin-bar--height))' : topOffset + 'px' });
        }

        // Calculate and set the breadcrumbs top offset
        function setBreadcrumbTopOffset() {
            // Set side navigaton draw offset from breadcrumbs
            breadcrumbTopOffset = $('.breadcrumbs').length ? topTitle.outerHeight() + $('.breadcrumbs').outerHeight() : topTitle.outerHeight();
        }

        setOnpageNavOffset();
        setBreadcrumbTopOffset();

        // Recalculate offsets when page resizes
        $(window).on('resize', throttle(100, () => {
            setOnpageNavOffset();
            setBreadcrumbTopOffset();
        }));

        // when on desktop
        onpageParents.each((index, item) => {
            hoverintent($(item)[0], (e) => {
                // use isMobile here to make function works after screen resize
                if (isMobile() == false) {
                    onpageNav.css({
                        '--onpage-nav-width': onpageNav.outerWidth() + 'px',
                        '--onpage-nav-breadcrumb-top-offset': breadcrumbTopOffset + 'px'
                    });

                    const parent = getItemParent(e.target);
                    parent.addClass(ACTIVE_SELECTOR);
                }

            }, (e) => {
                if (isMobile() == false) {
                    const parent = getItemParent(e.target);
                    parent.removeClass(ACTIVE_SELECTOR);
                }

            }).options({
                interval: 100,
                timeout: 300,
                sensitivity: 20
            })
        });
    }



    // when on mobile 
    $('.onpage-nav__title').on('click', function (e) {
        // use isMobile here to make function works after screen resize
        if (isMobile() == true) {
            $(this).parent().toggleClass('active');
            $(this).siblings('.onpage-nav__list').toggleClass('active');
            $('.onpage-nav__list').find('.active').removeClass('active');
        }
    })

    $('.onpage-nav__item .onpage-nav__link-trigger').on('click', function (e) {
        // use isMobile here to make function works after screen resize
        if (isMobile() == true) {
            const targetItem = $(this).closest('.onpage-nav__item');

            if (targetItem.hasClass('active')) {
                targetItem.removeClass('active');
                targetItem.find('.active').removeClass('active');

            } else {
                targetItem.toggleClass('active');
                targetItem.find('.onpage-nav__list').first().toggleClass('active');
                targetItem.siblings().removeClass('active');
                targetItem.siblings().find('.active').removeClass('active');
            }
        }
    })


    // if an element in the viewport
    $.fn.isInViewport = function () {
        var elementTop = $(this).offset() !== undefined ? $(this).offset().top : 0;
        var viewportTop = $(window).scrollTop();
        var viewportBottom = viewportTop + $(window).height();

        return elementTop < viewportBottom;
    };

    // if an element touch footer
    $.fn.isTouchFooter = function () {
        var elementTop = $(this).offset().top;
        var elementBottom = elementTop + $(this).outerHeight() + 1; // calculate include border height
        var footerTop = $('.footer').offset().top;

        return elementBottom > footerTop;
    };


    function getItemParent(item) {
        const parent = $(item).parents(`${ITEM_SELECTOR}`);

        return parent;

    }

    $(window).on('load scroll', throttle(100, function () {
        if ($('.footer').isInViewport()) {
            $('.onpage-nav').css({
                '--onpage-sub-nav-height': $('aside').height() + 'px'
            });
        } else {
            $('.onpage-nav').css({
                '--onpage-sub-nav-height': '100vh'
            });
        }
    }));

    $(document).ready(function () {

        if ($('.onpage-nav').length) {
            pageNav();
        }

    });

})(jQuery);
